@import '../../App.scss';

.idea-container {
    display: flex;
    min-height: 100vh;
    height: auto;
    background-image: url('../../images/Assets/6. Ideas/Ideas Background@4x.png');
    background-size: cover;

    .left-content {
        width: 50%;

        .main-text {
            margin-left: 10%;
            padding-top: 7%;
            font-family: 'Lato', sans-serif;
            color: $primary-grey;
            font-size: 2.5rem;
        }

        .description-text {
            margin: auto;
            max-width: 80%;
            text-align: justify;
            letter-spacing: 0.1rem;
            font-family: 'LatoRegular', sans-serif;
            color: #4c4c4c;
        }

        .description-bullets {
            text-align: left;
            max-width: 80%;
            font-family: 'PTSans', sans-serif;
            color: #4c4c4c;
            padding-top: 40px;
            padding: auto;
            margin: auto;

            ul {
                li {
                    padding: 0.5rem;
                }
            }

        }

        .button-item {
            text-align: left;
            max-width: 80%;
            padding: auto;
            margin: auto;
            padding-top: 5%;

            .contact-us-button {
                color: rgb(255, 55, 0);
                background-color: transparent;
                padding: 1rem;
                border: none;
                border-radius: 10px;
                border: 2px solid;
                border-image-slice: 5;
                border-image-source: $primary-gradient;
                cursor: pointer;
                transition: transform 1s ease-in-out;

                &:hover {
                    color: rgb(255, 255, 255);
                    background-color: $primary-gradient;
                    animation: color 0.5s ease-in;
                }
            }
        }
    }

    .right-content {
        width: 50%;

        .player {
            height: 550px;
            width: auto;
        }
    }
}

@media screen and (max-width: 900px) {
    .idea-container {

        .left-content {
            width: 100%;

            .main-text {
                text-align: center;
                font-size: 1.5rem;
            }

            .description-text {
                max-width: 85%;
                text-align: justify;
                letter-spacing: 0.1rem;
                font-size: 0.75rem;
                font-family: 'LatoRegular', sans-serif;
                color: #4c4c4c;
            }

            .button-item {
                text-align: center;

                .contact-us-button {

                    &:hover {}
                }
            }
        }

        .right-content {
            width: 100%;

            .player {
                height: 250px;
                width: auto;
            }
        }
    }
}