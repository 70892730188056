@import '../../App.scss';

.mainLoginContainer {
    background-color: #031637;
    border-radius: 5px;
    margin: auto;
    padding: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    max-width: 35%;

    .formMiniTitle {
        color: #2CD2A9;
        text-align: center;
        padding-top: 10%;
    }

    .formTitle {
        color: white;
        text-align: center;
        padding-top: 2%;
        padding-bottom: 6%;
    }

    .formContainer {
        display: flex;
        justify-content: flex-end;

        .fieldLabel {
            color: #A4B0BC;
            margin-left: 5%;
            font-size: 12px;
        }

        .textField {
            padding: auto;
            margin: 5%;
            margin-top: 2%;
            margin-bottom: 2%;
            width: 90%;

            .MuiInputBase-input {
                color: #303F6E;
                font-size: 12px;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                border-width: 1px;
            }

            .MuiInput-underline:after {
                border-bottom-color: red;
                border-width: 1px;
            }
        }
        .form-error{
            color: red;
            font-size: 12px;
            margin: 5%;
            margin-top: 0;
        }

        .checkbox {
            margin-left: 10px;
            color: #A4B0BC;
        }

        .rememberme {
            color: #A4B0BC;
            font-size: 10px;
            margin-top: 3%;
        }

        .forgotpassword {
            color: #A4B0BC;
            font-size: 10px;
            margin-top: 3%;
        }


        .loginButtonBack {
            text-align: center;

            .loginButton {
                border-radius: 20px;
                min-width: 30%;
                margin: 5%;
            }

            .MuiButton-root {
                background: linear-gradient(90deg, rgba(248, 68, 76, 1), rgba(85, 96, 244, 1) 100%);
            }

        }

        .signUpNow {
            text-align: center;

            .signUpNowText {
                color: #A4B0BC;
                font-size: 12px;
            }
        }

        .orContainer {
            display: flex;
            flex-direction: row;
            margin-top: 5%;
            margin-bottom: 5%;
            padding-left: 8%;
            padding-right: 8%;

            .or{
                color:#A4B0BC;
                font-size: 12px;
            }
        }

        .socialMediaIcons{
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            padding-bottom: 10%;

            .icon{
                color: #A4B0BC;
            }
        }

    }
}