@import '../../App.scss';

.top-button {
    position: absolute;
    margin-top: -7rem;
    padding: 1rem;
    margin-left: -75px;
    border: none;
    color: $primary-grey;
    border-radius: 10px;
    background-color: #ffffff;

    &:hover{
        color: white;
        background: black;
        transition: background-color 0.15s ease-in, color 0.15s ease-in;
    }
}