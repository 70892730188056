@import '../../App.scss';

.what-we-do-container {
    height: auto;
    width: 100%;
    min-height: 100vh;
    background-image: url('../../images/Assets/4. Services/Services Background.png');
    background-size: cover;
    background-position: center;

    .text-container {
        text-align: center;
        width: 100%;

        .main-heading {
            color: $primary-grey;
            margin: auto;
            padding-top: 7%;
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            max-width: 35%;
            font-size: 3rem;
        }
    }

    .bottom-para {
        margin: auto;
        margin-top: 2%;
        margin-bottom: 2%;
        text-align: center;
        max-width: 80%;

        .bottom-para-text {
            color: #4c4c4c;
            letter-spacing: 0.1rem;
            font-family: 'LatoRegular', sans-serif;
        }

    }


    .services-container {
        padding: 2%;
        display: flex;
        justify-content: space-evenly;

        .service-item {
            width: auto;
            max-width: 100px;
        }
    }


    .last-para {
        margin: auto;
        margin-top: 2%;
        margin-bottom: 2%;
        text-align: center;
        max-width: 80%;

        .last-para-text {
            letter-spacing: 0.1rem;
            color: white;
            font-family: 'LatoRegular', sans-serif;
        }

    }

}


@media screen and (max-width: 900px) {
    .what-we-do-container {
        background-size: cover;
        background-position-x: center;

        .text-container {
            width: 100%;

            .main-heading {
                max-width: 60%;
                font-size: 3rem;
            }
        }

        .bottom-para {
            width: 70%;
            margin-bottom: 2rem;
        }


        .services-container {
            padding: 1%;
            display: flex;
            justify-content: space-evenly;
        }


        .last-para {
            margin-top: 2rem;
            max-width: 60%;
        }

    }
}

@media screen and (max-width: 450px) {
    .what-we-do-container {
        background-size: cover;
        background-position-x: center;

        .text-container {
            width: 100%;

            .main-heading {
                max-width: 85%;
                font-size: 1.5rem;
            }
        }

        .bottom-para {
            text-align: justify;
            margin: auto;
            width: 85%;
            margin-top: 4%;
            margin-bottom: 4%;

            .bottom-para-text {
                letter-spacing: 0rem;
                font-size: 0.75rem;
            }

        }

        .services-container {
            padding: 1%;
            display: flex;
            justify-content: space-evenly;
        }

        .last-para {
            max-width: 85%;
            text-align: justify;

            .last-para-text {
                font-size: 0.75rem;
                padding-bottom: 20px;
            }
        }

    }
}