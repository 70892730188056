@import '../../App.scss';

.experitse-container {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-color: white;

    .text-container {
        text-align: center;
        width: 100%;

        .main-heading {
            text-align: center;
            color: $primary-grey;
            margin: auto;
            padding-top: 7%;
            font-family: 'Lato', sans-serif;
            max-width: 35%;
        }
    }

    .bottom-para {
        margin: auto;
        margin-top: 2%;
        margin-bottom: 2%;
        text-align: center;
        max-width: 80%;

        .bottom-para-text {
            color: #4c4c4c;
            letter-spacing: 0.1rem;
            font-family: 'LatoRegular', sans-serif;
        }

    }

    .icons-item {
        width: 100%;

        .icons-container {
            margin: 5%;
            padding: 1%;
            border-radius: 10px;
            background-color: #d7d6e5;
            display: flex;
            flex-direction: row;
            min-width: 80%;
            height: auto;

            .buttons-container {
                min-width: 300px;
                max-width: 485px;
                width: auto;

                .buttons-item {
                    display: flex;
                    flex-direction: row;
                    margin: 5px;

                    .button {
                        width: 15%;

                        .button-icons {
                            width: 30%;
                            cursor: pointer;
                            object-fit: contain;
                        }
                    }

                    .button-text {
                        font-size: 1em;
                        position: absolute;
                        margin-left: 35px;
                    }
                }
            }

            .icon-box {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-left: 4px;
                flex-wrap: wrap;

                .icon {
                    width: 80px;
                    height: 80px;
                    padding: 5px;
                    object-fit: contain;
                    background-color: white;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                }
            }

        }
    }
}


@media screen and (max-width: 750px) {
    .experitse-container {
        min-height: 100vh;
        height: auto;
        width: 100%;
        background-color: white;

        .text-container {
            margin-top: 0px;
            margin-bottom: 0px;

            .main-heading {
                text-align: center;
                margin-top: 7%;
                margin-bottom: 0px;
                padding-bottom: 0px;
                padding-bottom: 0px;
                font-size: 1.5rem;
                font-family: 'Lato', sans-serif;
                max-width: 85%;
            }
        }

        .bottom-para {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-bottom: 0px;
            padding-bottom: 0px;
            max-width: 85%;
            text-align: justify;

            .bottom-para-text {
                font-size: 0.75rem;
            }

        }

        .icons-item {
            margin: 2%;

            .icons-container {
                margin: 5%;
                padding: 1%;
                border-radius: 10px;
                background-color: #d7d6e5;
                display: flex;
                flex-direction: column;
                min-width: 80%;
                height: auto;

                .buttons-container {
                    width: auto;

                    .buttons-item {
                        display: flex;
                        flex-direction: column;
                        margin: 5px;

                        .button {
                            width: 15%;

                            .button-icons {
                                width: 30%;
                                cursor: pointer;
                                object-fit: contain;
                            }
                        }

                        .button-text {
                            font-size: 0.75em;
                            position: absolute;
                            margin-left: 25px;
                        }
                    }
                }

                .icon-box {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding-left: 4;
                    flex-wrap: wrap;
                }

            }
        }
    }
}