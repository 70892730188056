@import '../../App.scss';

.location-container {
    height: auto;
    width: 100%;
    min-height: 100vh;
    // background-image: url('../../images/Assets/8. Location/');
    background-size: cover;
    position: relative;

    .text-container {
        text-align: center;

        .main-heading {
            color: $primary-grey;
            margin: auto;
            margin-top: 7%;
            margin-bottom: none;
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            max-width: 35%;
        }
    }

    .bottom-para {
        text-align: center;
        margin: auto;
        max-width: 80%;
        margin-top: 0px;
        margin-bottom: none;

        .bottom-para-text {
            color: #4c4c4c;
            letter-spacing: 0.1rem;
            font-family: 'LatoRegular', sans-serif;
        }

    }

    .card-container {
        padding: 2rem;
        background-color: hsla(257, 20%, 32%, 0.2);
        border-radius: 40px;
        max-width: 60%;
        min-height: 220px;
        margin: auto;
        margin-top: 0px;

        .card-items-container{
            display: flex;
            justify-content: space-evenly;

            .straight-line-item {
                margin: auto;
                margin-right: 1rem;
                margin-left: 2rem;
        
                .straight-line {
                    height: 23vh;
                    width: 3.5px;
                    background-color: $primary-grey;
                    border-radius: 10px;
                }
            }

            .card-item{
                width: auto;
                text-align: center;
                margin: auto;

                .card-icon{
                    width: 50px;
                    height: auto;
                }
                
                .card-figure{
                    margin: auto;
                    color: $primary-grey;
                    font-family: 'Century', sans-serif;
                }

                .card-description{
                    font-family: 'CenturyItalic', sans-serif;
                }
            }
        }
    }
}