@import '../../App.scss';

.app-bar {
    background-color: #4a4161;
    position: fixed;
    color: white;
    width: 100%;
    z-index: 4;

    .toolbar-container {
        padding: 1% 2%;

        .toolbar {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo {
                width: auto;
                padding-left: 4rem;
                height: 2rem;
            }

            .desktop-menu {
                flex-grow: 1;
                display: flex;
                flex-direction: row;

                .menu-item {
                    color: white;
                    cursor: pointer;
                    width: max-content;
                    display: block;
                    margin-left: 40px;
                    border-bottom: 3px solid rgba(255, 255, 255, 0);
                    padding: 5px 10px;

                    &:hover {
                        border-bottom: 3px solid white;
                    }
                }
            }

            .mobile-menu {
                flex-grow: 1;
                display: none;
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .app-bar {

        .toolbar-container {
            padding: 1% 2%;

            .toolbar {
                display: flex;
                flex-direction: row;
                align-items: center;

                .logo {
                    padding-left: 4rem;
                    height: 2rem;
                    float: right;
                }

                .desktop-menu {
                    display: none;
                }

                .mobile-menu {
                    flex-grow: 1;
                    display: flex;
                }
            }
        }
    }

}