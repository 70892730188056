@import '../../App.scss';

.footer-container {
    display: flex;
    background-color: $primary-grey;
    padding: 4%;

    .logo-item {
        color: white;
        border-right: 2px solid rgb(255, 255, 255);

        .logo {
            width: auto;
            height: 1.75rem;

            &:hover {}
        }
    }

    .links-item {
        color: white;
        border-right: 2px solid rgb(255, 255, 255);

        .follow-us-title {
            font-size: 15px;
            font-family: 'OpenSans', sans-serif;
        }

        .link-text {
            text-decoration: none;
            color: white;
        }
    }

    .contact-us-item {
        color: white;

        .follow-us-title {
            font-size: 15px;
            font-family: 'OpenSans', sans-serif;
        }
    }
}

.last-bar {
    text-align: center;
    font-size: 12px;
    padding: auto;
    margin: auto;
    padding-bottom: 0.75rem;
    padding-top: 0.5rem;
    background: $primary-gradient;
    color: white;
}



@media screen and (max-width: 1200px) {
    .footer-container {
        flex-direction: column;
        padding: 2%;

        .logo-item {
            border-right: 0px solid rgb(255, 255, 255);

            .logo {

                &:hover {}
            }
        }

        .links-item {
            border-right: 0px solid rgb(255, 255, 255);

            .follow-us-title {}

            .link-text {}
        }

        .contact-us-item {

            .follow-us-title {}
        }
    }
}